
.StopContainer {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-top: 7%;
}


.DescriptionContainer {
    display: flex;
    left: 5%;
    width: 60%;
    padding-right: 15px;
    border: 1px solid;
    border-radius: 15px;
    background-color: #85b2a8;
}

.LabelText {
    font-size: 1.5rem;
    color: #1C5454;
    font-weight: bold;
}

.WhiteTextStyleBullet {
    font-size: 1.3rem;
    color: white;
    text-align: justify;

}

.ImageContainer {
    display: flex;
    right: 5%;
    width: 30%;
    height: 25vw;
    padding-left: 2%;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
}

.ButtonContainer {
    display: flex;
    margin-bottom: 50px;
}


.ButtonStyleStoppingView {
    font-size: 1.2rem;
    /* width: 30%; */
    margin-top: 0.5rem;
    padding: 10px;
    background-color: #257362;
    color: #fff;
    border-radius: 15px;
    font-weight: bold;
}







.StopSingleExerciseButtonStyle {
    font-size: 16px;
    width: 25vw;
    padding: 5px;
    background-color: #257362;
    color: #fff;
    border-radius: 15px;
    font-weight: bold;
}


.StartContainer {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #282c34;
}

.WebcamStyle {
    width: 100%;
    height: 100%;
}

.RedTransparentContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.2;
}

.OnScreenContainer {
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.9;
}

.EmptyContainer {
    display: flex;
    position: absolute;
    left: 50vw;
    bottom: 50vh;
    border: 1px;
    border-radius: 30px;
    justify-content: center;
}

.FeedbackContainer {
    display: flex;
    position: absolute;
    justify-content: center;
    left: 5%;
    top: 5%;
    padding-left:10px;
    padding-right: 10px;
    border-radius: 10px;
    flex-direction: column;
    opacity: 0.6;
    max-width: 50%;
}

.FeedbackText {
    font-size: 1.4rem;
    color: white;
    font-weight: bold;
    opacity: 1.0;
}

.OnScreenImageContainer {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    right: 5%;
    top:5%;
    width: 30vw;
    height: 25vw;
    /* max-width: 40%;
    height: 20%; */
    border: 1px;
    border-radius: 30px;
}

.PoseImage {
    height: 100%;
    width: 100%;
    border: 1px;
    border-radius: 10px;
}

.OnScreenButtonContainer {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    right: 5%;
    /* top: 20%; */
    width: 30vw;
    border: 1px;
    border-radius: 30px;
}

.ProgressBarContainer {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    bottom:5%;
    /* top: 70%; */
    right: 5%;
    max-width: 15%;
    /* max-height: 15%; */
    border: 1px;
    border-radius: 30px;
}
.ProgressBarContainerSingleExercise{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    bottom:12%;
    right: 5%;
    max-width: 14%;
    max-height: 14%;
    border: 1px;
    border-radius: 30px;
}

.TimerContainer {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 5%;
    bottom:5%;
    top: 70%;
    width: 20vw;
    /* height: 20vw; */
    border: 1px;
    border-radius: 10px;
    opacity: 0.6;
}
.TimerContainerSingleExercise{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 5%;
    bottom:10%;
    top: 70%;
    width: 20vw;
    /* height: 20vw; */
    border: 1px;
    border-radius: 10px;
    opacity: 0.6;
}

.TimerTextSingleExercise {
    font-size: 4vw;
    color: white;
    font-weight: bold;
    opacity: 1.0;
}

.TimerText {
    font-size: 4vw;
    color: white;
    font-weight: bold;
    opacity: 1.0;
}

#webcam {
    transform: rotateY(180deg);
}

#my-canvas {
    transform: rotateY(180deg);
}

.RootModalExercise {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width:100%;
}

.ModalButtonStyleExercisePage {
    font-size: 1rem;
    width: 100px;
    /* padding:5px; */
    background-color: #257362;
    color: #fff;
    border-radius: 15px;
    font-weight: bold;
    margin: 2px;
}

.SelectingButtonExercisePage {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    /* flex-direction: space-around; */
    /* background-color: red; */
    /* margin-top: 70px; */
}

.ButtonProgressBar {
    margin-bottom: 1px;
    /* background-color: white; */
    border-radius: 15px;
    opacity: 0.2,
}

.barContainerClass{
background-color: greem;
}

.wrapper {
    border: 3px solid #257362;
}

.container {
    background-color: #257362;
    border-radius: 15px;
    width: 100px;
}

.barCompleted {
    background-color: #E5ECEC;
    /* width: 80%; */
}

@media (max-width: 500px) {
    .StopContainer {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        margin-top: 15%;
    }

    .DescriptionContainer {
        display: flex;
        /* left: 5%; */
        width: 90%;
        padding-right: 15px;
        border: 1px solid;
        border-radius: 15px;
        background-color: #85b2a8;
    }

    .ImageContainer {
        display: flex;
        /* right: 5%; */
        width: 90%;
        height: 60vw;
        padding-bottom: 2%;
        border-radius: 15px;
        justify-content: center;
        align-items: center;
    }
    .ButtonStyleStoppingView {
        font-size: 1rem;
    }
    .LabelText {
        font-size: 1.2rem;
        color: #1C5454;
        font-weight: bold;
    }
    .WhiteTextStyleBullet {
        font-size: 1rem;
        color: white;
    }

    .FeedbackText {
        font-size: 1.2rem;
    }

    .ProgressBarContainer {
        max-width: 35%;
        max-height: 35%;
    }

    .OnScreenImageContainer {
        width: 40vw;
        height: 40vw;
    }
    .TimerContainer {
        width: 35vw;
    }

    .TimerText {
        font-size: 4vh;
    }
    .TimerTextSingleExercise{
        font-size: 4vw;
    }

    .StopSingleExerciseButtonStyle {
        font-size: 15px;
        width: 40vw;
        padding: 5px;
        background-color: #257362;
        color: #fff;
        border-radius: 15px;
        font-weight: bold;
    }
    
}