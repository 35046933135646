.RootHome{
    background-color: white;
    /* height: 90vw; */
    /* margin-top: 70px; */
}

.HeaderContainer{
    height: 40px;
    position: fixed;
    top: 0;
    z-index: 1300;
    width: 100%;
    box-shadow: 0px -2px 4px -1px rgba(0,0,0,0.2),0px -4px 5px 0px rgba(0,0,0,0.14)  ; 
}
.FooterContainer {
    background-color: #00252e;
    height: 40px;
    position: fixed;
    bottom: 0;
    z-index: 1300;
    width: 100%;
    box-shadow: 0px -2px 4px -1px rgba(0,0,0,0.2),0px -4px 5px 0px rgba(0,0,0,0.14)  ; 
}
.PageContainer{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* margin-top: 70px; */
}
.CardsContainer{
    background-color:#e5ecea;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    margin: 30px;
    margin-top: 70px;

}
.TextStyleExercise{
    font-size: 1.3rem;
    color:#00252e;
    font-weight: bold;
}
.CardAreaContainer{
    /* width: 25vw; */
    
    border:2px;
    border: radius 30px;
    }

.CardContentStyle{
    background-color:#6fc9a6;
    display:flex;
    justify-content:center;
    align-items:center;
}
.PlanContainer{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color:#B0C2BE;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    margin-top: 5vh;
    margin: 70px;
}
.Circle{
    display: flex;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    text-align: center;
}
.CircleText{
    margin: auto;
    font-size: 1.5vw;
    font-weight: bold;
    color:#1C5454;

}
.PlanContentStyle{
    background-color:#D5ECE4;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
    text-align: center;
    border-radius: 10px;
    padding: 10px;
}
.ButtonStyle{
    font-size: 1.2vw;
    width:20vw;
    padding:5px;
    background-color:#257362;
    color:#fff;
    border-radius:15px;
    font-weight: bold;
}
.StepText{
    margin-top: 10px;
    font-size: 1.2vw;
    color:#1C5454;
    font-weight: bold;
}
.GridStyle{
    display:flex;
    justify-content:center;
    align-items:center;
}