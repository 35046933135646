.RootHome {
    background-color: white;
    height: 100%;

    /* margin-top: 70px; */
}

.HeaderContainer {
    height: 40px;
    position: fixed;
    top: 0;
    z-index: 1300;
    width: 100%;
    box-shadow: 0px -2px 4px -1px rgba(0, 0, 0, 0.2), 0px -4px 5px 0px rgba(0, 0, 0, 0.14);
}

.FooterContainer {
    background-color: #00252e;
    height: 40px;
    position: fixed;
    bottom: 0;
    z-index: 1300;
    width: 100%;
    box-shadow: 0px -2px 4px -1px rgba(0, 0, 0, 0.2), 0px -4px 5px 0px rgba(0, 0, 0, 0.14);
}

.PageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    /* background-color: red; */
    /* margin-top: 70px; */
}

.TextContainer {
    /* background-color: #e7efec; */
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    /* margin: 70px; */
    margin-top: 10px;

}

.TextTermCondition {
    /* background-color:#e7efec; */
    border-radius: 10px;
    padding: 10px;
    text-align: center;
}

.TextStyleBestBrowser {
    font-size: 1rem;
    color: #1C5454;
    /* font-weight: bold; */
}

.TextStyleTitle {
    font-size: 1.3rem;
    color: #1C5454;
    font-weight: bold;
}

.TextStyleTermCondition {
    /* font-size: 1.2rem; */
    color: #1C5454;
    /* font-weight: bold; */
}

.TextStyleSmaller {
    font-size: 0.8vw;
    color: #1C5454;
    font-weight: bold;
}

.PlanContainerTutor {
    /* margin-top: 5vh; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    margin-top: 5vh;
    /* margin: 70px; */
    width: 95%;
    /* margin: 70px; */
}


.ButtonStyleStarting {
    font-size: 1.2rem;
    width: 20vw;
    padding: 5px;
    background-color: #257362;
    color: #fff;
    border-radius: 15px;
    font-weight: bold;
    margin-top: 10px;
}

.StepText {
    margin-top: 10px;
    font-size: 1.2vw;
    color: #1C5454;
    font-weight: bold;
}

.TutorialStepsContainer {
    display: flex;
    flex-direction: row-reverse;
    margin: 5px;
}

.WhiteTextStyle {
    font-size: 1.5vw;
    color: white;
}


.FixedButtonContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    bottom: 70px;
    z-index: 1300;
    width: 100%;
    /* box-shadow: 0px -2px 4px -1px rgba(0,0,0,0.2),0px -4px 5px 0px rgba(0,0,0,0.14)  ;  */
}

.rowStyleSteps {
    display: flex;
    flex-direction: row;
}

.RootModal {
    display: flex;
    position: absolute;
    right:30%;
    left: 30%;
    top: 30%;
    bottom: 30%;
    justify-content: center;
    align-items: center;
    width:'60%',
    /* pointer-events: auto; */
}

.ModalButtonStyle {
    font-size: 1.1rem;
    width: 100px;
    /* padding:5px; */
    background-color: #257362;
    color: #fff;
    border-radius: 15px;
    font-weight: bold;
}

.SelectingButton {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.TipTitleStyle {
    color: #1C5454;
    font-size: 1rem;
    font-weight: bold;
}

.ImageBoxStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 50vw;

}

.containertutorialText {
    max-width: 70vw;
    margin-bottom: 100px;
    margin-left :20px;
    /* [theme.breakpoints.down('sm')]: {
        maxWidth: '90vw',
        // marginLeft:'0px',
        
    }, */
}
.tipTitle {
    color: #1C5454;
    /* // fontSize: '1.2rem',
    // [theme.breakpoints.down('sm')]: {
    //     fontSize: '1rem',
    // }, */
}
.bodyText {
    color: #1C5454;
    /* // [theme.breakpoints.down('sm')]: {
    //     fontSize: '0.8rem',
    // }, */
}
.listItem {
    color: #1C5454;
    text-align: justify;
    margin-left: 20px;
    /* // [theme.breakpoints.down('sm')]: {
    //     fontSize: '0.8rem',
    // }, */
}
/* .extraText {
    color: red,
    /* // [theme.breakpoints.down('sm')]: {
    //     fontSize: '0.8rem',
    // }, */
/* }  */
.cameraModal {
    width:60%,
    /* [theme.breakpoints.down('sm')]: {
        width:'90%',
    }, */
}

@media (max-width: 600px) {


    .rowStyleSteps {
        display: flex;
        flex-direction: column;
    }

    .TutorialStepsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }

    .ImageBoxStyle {
        max-width: 85vw;
    }

    .ButtonStyleStarting {
        font-size: 1rem;
        width: 30vw;
    }
    .RootModal {
        width:'80%',
        /* pointer-events: auto; */
    }
    .containertutorialText {
        max-width: 90vh;
        margin-left: 0;
    }
    .cameraModal {
        width: 90%;
    }
}