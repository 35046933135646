.RootExercise{
    background-color: #e7efec;
    /* height: 90vw; */
    width: 100%;
}
.HeaderContainer{
    height: 40px;
    position: fixed;
    top: 0;
    z-index: 1300;
    width: 100%;
    box-shadow: 0px -2px 4px -1px rgba(0,0,0,0.2),0px -4px 5px 0px rgba(0,0,0,0.14)  ; 
}
.ExerciseMonitorContainer{
    margin-top: 30px;
}
.FooterContainer {
    background-color: #00252e;
    height: 40px;
    position: fixed;
    bottom: 0;
    z-index: 1300;
    width: 100%;
    box-shadow: 0px -2px 4px -1px rgba(0,0,0,0.2),0px -4px 5px 0px rgba(0,0,0,0.14)  ; 
}